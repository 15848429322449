/* eslint-disable */
// @ts-nocheck

import API from '../../api/apiInstance';

export const listContent = async (): any => {
    const res = await API.get(`/ingestion-metadata`);
    return res;
};

export const addContent = async (payload: any): any => {
    const res = await API.post(`/ingestion-metadata`, payload);
    return res;
};

export const deleteContent = async (externalId: number): any => {
    return await API.delete(`/ingestion-metadata/${externalId}`);
};

export const getContentById = async (externalUid: string): any => {
    return API.get(`/ingestion-metadata/${externalUid}`);
};

export const updateContent = async (payload: any, externalUid: string): any => {
    const content = API.patch(`/ingestion-metadata/${externalUid}`, payload);
    return content;
};

export const validateURL = async (url: string): any => {
    const res = API.get(`/ingestion-metadata/verify-website?url=${encodeURIComponent(url)}`);
    return res;
};


