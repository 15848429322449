/*eslint-disable*/
import { useCallback, useState } from 'react';
import type { ChangeEvent, FC, MouseEvent } from 'react';

import {
  Box,
  Typography,
  Stack,
  Tooltip,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import {
  getChatsBySessionId,
  // getConversationBySessionAndChatId,
} from 'src/api/chat';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';


export const HierarchialListItem: FC<any> = ({
  id,
  option,
  setSelectedSessionId,
  selectedSessionId,
  setSelectedChatId,
  selectedChatId,
  loadConversationsBySessionAndChatId,
  setConversations,
  sessionStartDate,
  sessionEndDate,
  sessionId,
  setSessionId,
  setSelectedSessionStartDate,
  setSelectedSessionEndDate,
  setCurrentChatSession,
  email,
}) => {
  const [expand, setExpanded] = useState<boolean>(false);
  const [children, setChildren] = useState<any>(null);
  const theme = useTheme();

  const truncateTitle = (title, maxLength) => {
    if (title.length <= maxLength) {
      return title;
    }
    return title.substring(0, maxLength) + '...';
  };

  const loadChatsBySessionId = async (sid: string) => {
    setSessionId(option.id);
    setSelectedSessionEndDate(new Date(sessionEndDate).toLocaleTimeString());
    setSelectedSessionStartDate(new Date(sessionStartDate).toLocaleTimeString());
    setSelectedSessionId(sid);
    setSelectedChatId(null);
    setConversations(null);
    const emailString = typeof email === 'string' ? email : '';
    const chats = await getChatsBySessionId(+sid, emailString)
      .then((resp) => resp?.data)
      .catch((err) => {
        console.log(err);
      });

    setChildren(
      chats.map((data) => ({
        id: data.external_chat_id,
        parentId: id,
        label: truncateTitle(data.title, 35),
        fullTitle: data.title,
      }))
    );
    const currentChat = chats ?? [{}];
    setCurrentChatSession({ userName: currentChat[0].username, email: currentChat[0].email })
  };

  return (
    <Box
      sx={{
        p: 0.5,
        cursor: 'pointer',
        background: 'white',
        //option.parentId === selectedSessionId && option.id === selectedChatId
        //  ? theme.palette.primary.main
        // : 'white',
      }}
    >
      <Stack
        direction="row"
        component="div"
        // sx={{
        //   '&:hover': option.id !== selectedSessionId &&
        //     option.id !== selectedChatId && {
        //       background: '#F3f3f3',
        //     },
        // }}
        sx={{ background: 'white' }}
        onClick={async (e) => {
          e.stopPropagation();
          setExpanded(true);
          if (!id.startsWith('child')) {
            loadChatsBySessionId(id);
          } else {
            await loadConversationsBySessionAndChatId(
              option.parentId,
              option.id,
              sessionId,
              sessionStartDate,
              sessionEndDate,
            );
          }
        }}
      >
        {!expand ? (
          <>
            {id.startsWith('child') ? (
              <ChatBubbleOutlineIcon
                style={{
                  color:
                    option.parentId === selectedSessionId &&
                      option.id === selectedChatId
                      ? 'white'
                      : '#000000',
                }}
                sx={{
                  fontSize: {
                    xs: '1rem',
                    sm: '1rem',
                    md: '1.125rem',
                    lg: '1.375rem',
                    xl: '1.375rem',
                  },
                  mr: 1,
                }}
              />
            ) : (
              <AddIcon sx={{ mr: 1, border: '1px solid #000000', mb: 1, mt: 1 }} />
            )}
          </>
        ) : (
          <>
            {id.startsWith('child') ? (
              <ChatBubbleOutlineIcon
                style={{
                  color:
                    option.parentId === selectedSessionId &&
                      option.id === selectedChatId
                      ? '#43B6E1'
                      : '#000000',
                }}
                sx={{
                  fontSize: {
                    xs: '1rem',
                    sm: '1rem',
                    md: '1.125rem',
                    lg: '1.375rem',
                    xl: '1.375rem',
                  },
                  mr: 1,
                }}
              />
            ) : (
              <RemoveIcon
                sx={{ mr: 1, border: '1px solid #000000', mb: 1, mt: 1 }}
                onClick={(e) => {
                  e.stopPropagation();
                  setExpanded(false);
                }}
              />
            )}
          </>
        )}
        {id.startsWith('child') ? (
          <Tooltip
            title={<Typography sx={{ color: 'white', pl: 2 }}>{option.fullTitle}</Typography>}
            sx={{
              mb: 0,
              maxWidth: '400px',
              width: 'auto',
            }}
          >
            <Typography
              sx={{
                color: option.parentId === selectedSessionId && option.id === selectedChatId
                  ? '#333333'
                  : 'black',
                fontSize: '0.9rem',
                mt: 0,
                fontWeight: !id.startsWith('child') && option.is_active ? '900' : 'normal',
              }}
            >
              {option.label}
            </Typography>
          </Tooltip>
        ) : (
          <Typography
            sx={{
              color: option.parentId === selectedSessionId && option.id === selectedChatId
                ? '#2B5D8C'
                : 'black',
              fontSize: '0.9rem',
              mt: 1,
              mb: 1,
              fontWeight: !id.startsWith('child') && option.is_active ? '900' : 'normal',
            }}
          >
            {option.label}
          </Typography>
        )}

      </Stack>
      <Box sx={{ ml: '10px' }}>
        {expand && children && (
          <>
            {children.map((child) => (
              <HierarchialListItem
                loadConversationsBySessionAndChatId={
                  loadConversationsBySessionAndChatId
                }
                setCurrentChatSession={setCurrentChatSession}
                setConversations={setConversations}
                setSelectedSessionId={setSelectedSessionId}
                selectedSessionId={selectedSessionId}
                setSelectedChatId={setSelectedChatId}
                selectedChatId={selectedChatId}
                onClick={(e) => { }}
                onMouseOver={() => { }}
                id={`child-${child.id}`}
                sessionStartDate={sessionStartDate}
                sessionEndDate={sessionEndDate}
                setSelectedSessionStartDate={setSelectedSessionStartDate}
                setSelectedSessionEndDate={setSelectedSessionEndDate}
                sessionId={sessionId}
                setSessionId={setSessionId}
                option={child}
                email={email}
              />
            ))}
          </>
        )}
      </Box>
    </Box>
  );
};