export const THEMES = {
  LIGHT: 'LIGHT',
  DARK: 'DARK',
  NATURE: 'NATURE'
};

export const NETWORK_ERROR_MESSAGE = 'Network Error';

export const AUTH_TOKEN_REFRESH_INTERVAL = 10 * 60 * 1000;

export const ROLES_LABELS = {
  CUSTOMER: 'Customer',
  ADMIN: 'Admin'
};

export const ROLES_VALUES = {
  CUSTOMER: 'customer',
  ADMIN: 'admin'
};

export const ROLE_PERMISSIONS_LABELS = {
  FULL_ACCESS: 'Full Access',
  READ: 'Read',
  WRITE: 'Write',
  CREATE: 'Create',
  DELETE: 'Delete',
};

export const ROLE_PERMISSIONS_VALUES = {
  FULL_ACCESS: 'full_access',
  READ: 'read',
  WRITE: 'write',
  CREATE: 'create',
  DELETE: 'delete'
};

export const USER_ROLES = [
  { text: ROLES_LABELS.CUSTOMER, value: ROLES_VALUES.CUSTOMER },
  { text: ROLES_LABELS.ADMIN, value: ROLES_VALUES.ADMIN },
];

export const ROLE_PERMISSIONS = [
  { text: ROLE_PERMISSIONS_LABELS.FULL_ACCESS, value: ROLE_PERMISSIONS_VALUES.FULL_ACCESS },
  { text: ROLE_PERMISSIONS_LABELS.READ, value: ROLE_PERMISSIONS_VALUES.READ },
  { text: ROLE_PERMISSIONS_LABELS.WRITE, value: ROLE_PERMISSIONS_VALUES.WRITE },
  { text: ROLE_PERMISSIONS_LABELS.CREATE, value: ROLE_PERMISSIONS_VALUES.CREATE },
  { text: ROLE_PERMISSIONS_LABELS.DELETE, value: ROLE_PERMISSIONS_VALUES.DELETE }
];

export const ERROR_CODES = {
  USER_NOT_FOUND: 'USER__NOT_FOUND'
};

export const ToolBarHeight = '70px';
export const ChatMessageWidth = 1420;

export const CONTENT_SOURCE_TYPES = [
  { text: 'document', value: 'Document' },
];
export const CHAT_ANSWER_LOADER = 'LOADER';

export const RESPONSE_MODEL_TYPES = [
  {
    name: 'Claude-Sonnet-v3:5',
    value: 'anthropic.claude-3-5-sonnet-20240620-v1:0',
    type: 'Bedrock'
  },
  {
    name: 'Claude-Sonnet-v3',
    value: 'anthropic.claude-3-sonnet-20240229-v1:0',
    type: 'Bedrock',
    disabled: true
  },
  {
    name: 'Claude-v2:1',
    value: 'anthropic.claude-v2:1',
    type: 'Bedrock',
    disabled: true
  },
  /*  {
    name: 'Bedrock Claude-v2:1',
    value: 'anthropic.claude-v2:1',
    type: 'Bedrock'
  },
  {
    name: 'Bedrock Claude-v2',
    value: 'anthropic.claude-v2',
    type: 'Bedrock'
  },
  {
    name: 'Bedrock Claude-Instant',
    value: 'anthropic.claude-instant-v1',
    type: 'Bedrock'
  },  */
  // {
  //   name: 'SageMaker Jaise13b',
  //   value: 'Jaise-13b',
  //   type: 'SageMaker'
  // }
];
