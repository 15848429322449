import React, { useState, useEffect } from 'react';
import UserUtterances from './UserUtterances'; // Adjust the path if necessary
import UserInteractions from './UserInteractions'; // Adjust the path if necessary
import Widgets from './Widgets';
import { subDays } from 'date-fns';
import { Grid, Card, CardContent, Button } from '@material-ui/core';
import BotResponses from './BotResponses';
import TopDocumentsHitted from './TopDocumentsHitted';
import '../dashboard/chat/styles/chat.css';
import LatencyAndTokens from './LatencyAndTokens';

const cardStyle = {
  borderRadius: '10px',
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
  minHeight: '400px',
};

const AnalyticsDashboard = () => {
  const [selectedChart, setSelectedChart] = useState(null);
  const [drilldownData, setDrilldownData] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const [showAllData, setShowAllData] = useState(false);
  const [startDate, setStartDate] = useState(subDays(new Date(), 7));
  const [endDate, setEndDate] = useState(new Date());

  const handleChartClick = (chartName, data, showButtonClicked = false) => {
    setSelectedChart(chartName);
    setSelectedData(data);
    setShowAllData(showButtonClicked);
    setDrilldownData(data);
  };
  const handleBackToDashboard = () => {
    setSelectedChart(null);
    setShowAllData(false);
  };
  return (
    <div
      className="App"
      style={{ background: '#FAFAFA' }}
    >
      <Widgets
        setStartDate={setStartDate} setEndDate={setEndDate} startDate={startDate} endDate={endDate}
      />
      {selectedChart ? (
        <>
          <Button
            onClick={handleBackToDashboard}
            className="chartsButtons"
            style={{ margin: '20px' }}
          >
            Go to Dashboard
          </Button>
          <Grid container spacing={2}>
            {selectedChart === 'TopDocumentsHitted' && (
              <Grid item xs={12} sm={12} className="hide">
                <Card style={cardStyle}>
                  <CardContent>
                    <TopDocumentsHitted
                      onChartClick={handleChartClick}
                      selectedData={selectedData}
                      startDate={startDate}
                      endDate={endDate}
                      showAllData={showAllData}
                    />
                  </CardContent>
                </Card>
              </Grid>
            )}
            {selectedChart === 'BotResponses' && (
              <Grid item xs={12} sm={12}>
                <Card style={cardStyle}>
                  <CardContent>
                    <BotResponses
                      onChartClick={handleChartClick}
                      selectedData={selectedData}
                      startDate={startDate}
                      endDate={endDate}
                      showAllData={showAllData}
                    />
                  </CardContent>
                </Card>
              </Grid>
            )}
            {selectedChart === 'UserInteractions' && (
              <Grid item xs={12} sm={12}>
                <Card style={cardStyle}>
                  <CardContent>
                    <UserInteractions
                      onChartClick={handleChartClick}
                      selectedData={selectedData}
                      startDate={startDate}
                      endDate={endDate}
                      showAllData={showAllData}
                    />
                  </CardContent>
                </Card>
              </Grid>
            )}
            {selectedChart === 'LatencyAndTokens' && (
              <Grid item xs={12} sm={12}>
                <Card style={cardStyle}>
                  <CardContent>
                    <LatencyAndTokens
                      onChartClick={handleChartClick}
                      selectedData={selectedData}
                      startDate={startDate}
                      endDate={endDate}
                      showAllData={showAllData}
                    />
                  </CardContent>
                </Card>
              </Grid>
            )}
            {selectedChart === 'UserUtterances' && (
              <Grid item xs={12} sm={12}>
                <Card style={cardStyle}>
                  <CardContent>
                    <UserUtterances
                      onChartClick={handleChartClick}
                      selectedData={selectedData}
                      startDate={startDate}
                      endDate={endDate}
                      showAllData={showAllData}
                    />
                  </CardContent>
                </Card>
              </Grid>
            )}
          </Grid>
        </>
      ) : (
        <Grid container spacing={2} style={{ padding: '20px' }}>
          <Grid item xs={12} sm={6}>
            <Card style={cardStyle}>
              <CardContent>
                <UserInteractions
                  onChartClick={handleChartClick}
                  startDate={startDate}
                  endDate={endDate}
                  showAllData={showAllData}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card style={cardStyle}>
              <CardContent>
                <LatencyAndTokens
                  onChartClick={handleChartClick}
                  startDate={startDate}
                  endDate={endDate}
                  showAllData={showAllData}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card style={cardStyle}>
              <CardContent>
                <UserUtterances
                  onChartClick={handleChartClick}
                  startDate={startDate}
                  endDate={endDate}
                  showAllData={showAllData}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card style={cardStyle}>
              <CardContent>
                <BotResponses
                  onChartClick={handleChartClick}
                  startDate={startDate}
                  endDate={endDate}
                  showAllData={showAllData}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} className="hide">
            <Card style={cardStyle}>
              <CardContent>
                <TopDocumentsHitted
                  onChartClick={handleChartClick}
                  startDate={startDate}
                  endDate={endDate}
                  showAllData={showAllData}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default AnalyticsDashboard;
