/*eslint-disable*/
const colors = {
  buttonHover: '#0663A7',
  buttonColor: '#43B6E1',
  mainColor: '#0663A7',
  white: '#FFFFFF',
  black: '#000000',
  darkGray2: '#858585',
  mainColorBright: '#858585',
  bodyText: '#3C3C3C',
  active: '#80CC28',
  darkBlue: '#0A0F34',
  darkBlueHover: '#121844',
  lightGrey: '#EDEDED',
  errorDeactive: '#FF3D00',
  darkGrey: '#233746',
  littleDarkGrey: '#CBCBCB',
  lightGreyFiftyPercent: '#F3F3F3',
  bgBlackShade: '#53565a',
  mainColorDark: '#53565a',
  mainColorLight: '#A4A4A4',
  chatSampleCardText: '#25323E',
  chatSampleCardBg: '#F2F2F2',
  chatSideBar: '#53565a',
  chatSideBarHover: '#162532',
  chatNewDisableBtn: '#4B4B4B',
  chatNewDisableText: '#919191',
};
export default colors;