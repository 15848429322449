import createSvgIcon from '@material-ui/core/utils/createSvgIcon';

const LogoutIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" color="#10385D" width="22" height="22" viewBox="0 0 22 22" fill="none">
    <path
      d="M4.58333 19.25C4.07917 19.25 3.64742 19.0703 3.28808 18.711C2.92875 18.3517 2.74939 17.9202 2.75 17.4167V4.58333C2.75 4.07917 2.92967 3.64742 3.289 3.28808C3.64833 2.92875 4.07978 2.74939 4.58333 2.75H11V4.58333H4.58333V17.4167H11V19.25H4.58333ZM14.6667 15.5833L13.4063 14.2542L15.7438 11.9167H8.25V10.0833H15.7438L13.4063 7.74583L14.6667 6.41667L19.25 11L14.6667 15.5833Z"
      fill="#43B6E1"
    />
  </svg>,
  'LogoutIcon'
);

export default LogoutIcon;
