import createSvgIcon from '@material-ui/core/utils/createSvgIcon';

const MenuBurgerBlack = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="50" height="51" viewBox="0 0 50 51" fill="none">
    <circle cx="25" cy="25.5" r="25" fill="#F0F0F0" fillOpacity="0.1" />
    <mask
      id="mask0_597_3319"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="6"
      y="7"
      width="38"
      height="37"
    >
      <rect x="6.6665" y="7.1665" width="36.6667" height="36.6667" rx="18.3333" fill="#43B6E1" />
    </mask>
    <g mask="url(#mask0_597_3319)">
      <path
        d="M12.7778 34.6668C11.934 34.6668 11.25 33.9828 11.25 33.1391C11.25 32.2953 11.934 31.6113 12.7778 31.6113H37.2222C38.066 31.6113 38.75 32.2953 38.75 33.1391C38.75 33.9828 38.066 34.6668 37.2222 34.6668H12.7778ZM12.7778 27.0279C11.934 27.0279 11.25 26.3439 11.25 25.5002C11.25 24.6564 11.934 23.9724 12.7778 23.9724H37.2222C38.066 23.9724 38.75 24.6564 38.75 25.5002C38.75 26.3439 38.066 27.0279 37.2222 27.0279H12.7778ZM12.7778 19.3891C11.934 19.3891 11.25 18.705 11.25 17.8613C11.25 17.0175 11.934 16.3335 12.7778 16.3335H37.2222C38.066 16.3335 38.75 17.0175 38.75 17.8613C38.75 18.705 38.066 19.3891 37.2222 19.3891H12.7778Z"
        fill="#43B6E1"
      />
    </g>
  </svg>,
  'TIIMenuBurgerBlack'
);

export default MenuBurgerBlack;
