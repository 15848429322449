/*eslint-disable*/
import { useRef, useState, useEffect } from 'react';
import type { ChangeEvent, FC, KeyboardEvent } from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  InputAdornment,
  TextField,
  Theme,
  Tooltip,
  createStyles,
  makeStyles,
  withStyles,
  Autocomplete,
  Stack
} from '@material-ui/core';
import { useSelector } from 'src/store';
import { isEmpty, transform } from 'lodash';
import { useTranslation } from 'react-i18next';
//import SendButton from 'src/icons/DEWA-Send';
import SendButton from 'src/icons/SendButton';
import colors from 'src/theme/colors';
import useSettings from 'src/hooks/useSettings';
import { MicOutlined } from '@material-ui/icons';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';


interface ChatMessageAddProps {
  disabled?: boolean;
  onSend?: (value: string) => void;
}

const CssTextField = withStyles((theme: Theme) => ({
  root: {
    // '& label.Mui-focused': {
    //   color: '#A4A4A4',
    //   backgroundColor: '#ffffff'
    // },
    // '& .MuiInput-underline:after': {
    //   borderBottomColor: '#9B9B9B',
    // },
    '& .MuiOutlinedInput-root': {
      color: '#53565a',
      padding: '0px 21px',
      [theme.breakpoints.down(1400)]: {
        padding: '0px 18px',
      },
      [theme.breakpoints.down(1200)]: {
        padding: '0px 15px',
      },
      [theme.breakpoints.down(760)]: {
        padding: '0px 15px',
      },
      borderColor: '#9B9B9B',
      '& fieldset': {
        borderRadius: '5px',
      },
      '&:hover fieldset': {
        borderColor: '#9B9B9B',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#9B9B9B',
      },
    },
  },
}))(TextField);

const useStyles = makeStyles((theme: Theme) => ({
  textField: {
    width: 300,
    margin: 100,
  },
  //style for font size
  resize: {
    fontSize: '0.875rem',
    [theme.breakpoints.down(1400)]: {
      fontSize: '1rem',
    },
    [theme.breakpoints.down(1200)]: {
      fontSize: '0.875rem',
    },
    [theme.breakpoints.down(760)]: {
      fontSize: '0.875rem',
    },
    letterSpacing: '0.36px',
    fontWeight: 400,
    color: 'rgba(37, 55, 70, 1)',
  },
  option: {
    "&:hover": {
      backgroundColor: "#F2F2F2 !important"
    }
  }
}));


const ChatMessageAdd: FC<ChatMessageAddProps> = (props) => {
  const classes = useStyles();
  const { disabled, onSend, ...other } = props;
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const searchedQuestion = useRef(null);
  const [isFocused, setFocus] = useState(false);
  const { chatQuestions = [] } = useSelector((state) => state.chat);
  const [body, setBody] = useState<string>('');
  const sendKeys = ['enter', 'numpadenter'];
  const { settings } = useSettings();
  const styles = useStyles();

  const { t } = useTranslation();
  const setInputFocus = (state)=>{
    const notEmpty = !!searchedQuestion.current?.value;
     if(notEmpty) return setFocus(true)
     setFocus(state);
  }

  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition();

  useEffect(() => {
    setBody(transcript);
  }, [transcript]);

  const startListen = () => {
    SpeechRecognition.startListening({ language: 'ar-KW' })
  }

  const handleAttach = (): void => {
    fileInputRef.current.click();
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setBody(event.target.value);
  };

  const handleChangeOfAutoComplete = (event: ChangeEvent<HTMLInputElement>, value: any): void => {
    setBody(value);
  };

  const handleSend = (): void => {
    if (!body || isEmpty(body.trim())) {
      return;
    }

    onSend?.(body.trim());
    setBody('');
    searchedQuestion.current.value = "";
    searchedQuestion.current.blur();
    setInputFocus(false);
  };

  const handleKeyUp = (event: KeyboardEvent<HTMLInputElement>): void => {
    if (sendKeys.includes(event.code?.toLowerCase()) && !disabled && body.trim().length > 0) {
      handleSend();
      searchedQuestion.current.value = "";
      searchedQuestion.current.blur();
      setInputFocus(false);
    }
  };

  const handleKeyPress = (event: any) => {
    if (sendKeys.includes(event.code?.toLowerCase())) {
      event.preventDefault();
    }
  };

  const handleSpeechButtonClick = () => {
    startListen();
  }
  return (
    <Box
      sx={{
        alignItems: 'center',
        // backgroundColor: 'background.paper',
        display: 'flex',
        flexShrink: 0,
        borderRadius: '10px',
      }}
      {...other}
    >
      <Autocomplete
        fullWidth
        freeSolo
        id="free-solo-2-demo"
        disableClearable
        options={chatQuestions.map((text) => text)}
        onChange={handleChangeOfAutoComplete}
        value={body}
        classes={{
          option: styles.option
        }}
        renderInput={(params) => (
          <CssTextField
            {...params}
            onChange={handleChange}
            onKeyUp={handleKeyUp}
            inputRef={searchedQuestion}
            onKeyPress={handleKeyPress}
            placeholder={t("dewaChat.searchPlaceholder")}
            value={body}
            onFocus={()=>setInputFocus(true)}
            onBlur={()=>setInputFocus(false)}
            sx={{
              backgroundColor: 'white',
              fontWeight: '700',
            }}
            InputProps={{
              ...params.InputProps,
              inputProps: {
                ...params.inputProps,
                maxLength: 4096
              },
              type: 'text',
              endAdornment: (
                <InputAdornment position="end"
                  sx={{ height: '100%', maxHeight: 'unset', flexDirection: 'column', marginTop: 'auto', borderRadius: '0px' }}>
                  <Stack spacing={1} direction="row">
                    <IconButton onClick={handleSpeechButtonClick}>
                      <MicOutlined sx={{
                        fill: listening ?'#43B6E1': 'gray',
                        '&:hover': {
                          fill: listening ?'#43B6E1': 'gray',
                        },
                      }} />
                    </IconButton>
                    <Tooltip title="Send" sx={{ borderRadius: '10px' }}>
                      <IconButton
                        sx={{
                          borderRadius: '30%',
                          backgroundColor: body.trim().length < 1 || disabled ? 'transparent' : `${colors.buttonColor} !important`,
                          padding: '0.375rem 12px',
                          margin: '0.375rem',
                          height: '37px',
                          marginTop: '5px !important',
                          width: '39px',
                          marginRight: 0,
                          cursor: 'pointer !important',
                          opacity: 1,
                          '&:disabled': {
                            opacity: 0.3, // Opacity when disabled
                            cursor: 'not-allowed', // Change cursor on disabled state
                          }
                        }}
                        disabled={body.trim().length < 1 || disabled}
                        onClick={handleSend}
                      >
                        <SendButton
                          style={{
                            color: body.trim().length < 1 || disabled ? colors.darkGrey : colors.white,
                            cursor: 'pointer !important',
                            // transform: 'rotate(180deg)' //settings.direction === 'rtl' ? 
                          }}
                          sx={{
                            fontSize: {
                              xs: '0.875rem',
                              sm: '0.875rem',
                              md: '1rem',
                              lg: '1.1rem',
                              xl: '1.375rem',
                            }
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                </InputAdornment>
              ),
              classes: { input: classes.resize },
            }}
          />
        )}
      />
      <input hidden ref={fileInputRef} type="file" />
    </Box>
  );
};

ChatMessageAdd.propTypes = {
  disabled: PropTypes.bool,
  onSend: PropTypes.func,
};

ChatMessageAdd.defaultProps = {
  disabled: false,
};

export default ChatMessageAdd;
