import {
  Avatar,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
  Box,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import senderAvatar from 'src/icons/Vector.png';
import LogoutIcon from 'src/icons/LogoutIcon';
import SettingsIcon from 'src/icons/SettingsIcon';
import TIIMenuBurger from 'src/icons/TII-MenuBurger';
import TIIMenuBurgerBlack from 'src/icons/TII-MenuBurger-Black';
import MenuClose from 'src/icons/TII-MenuClose';
import { useSelector } from 'src/store';

interface NavItem {
  icon?: JSX.Element;
  text: string;
  redirectTo: string;
  visible?: boolean;
}

export const DEPARTMENTS = {
  1: 'Traffic',
  2: 'Roads',
  3: 'Parking'
};

export const SECURITY_CLEARANCE = {
  public: 'Public',
  private: 'Private',
  secret: 'Secret'
};

export const ROLES = {
  1: 'Admin',
  2: 'User',
  3: 'Super Admin',
};

const MainNav: FC<any> = ({ color = '#FFF' }: { color: string }) => {
  const { t } = useTranslation();
  const { userProfile = {} } = useSelector((state) => state.user);
  const admin = userProfile && (userProfile.role_id === 1 || userProfile.role_id === 3);
  const menuItems: NavItem[] = [
    {
      icon: <LogoutIcon viewBox="0 0 20 20" />,
      text: t('dewaMenu.logout'),
      redirectTo: '/auth/logout',
    },
  ];
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (open) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(null);
  };
  const theme = useTheme();

  const getUserInfo = () => (
    <>
      <Box>
        <Avatar
          variant="square"
          className="user-profile-avatar"
          sx={{
            height: '48px',
            width: '48px',
            gap: '40px',
            borderRadius: '50%',
            mr: 1
          }}
        >
          <img
            src={senderAvatar}
            alt={senderAvatar}
            style={{ height: '24px', width: '24px' }}
          />
        </Avatar>
      </Box>
      <Box>
        <Typography sx={{ color: '#000000', fontSize: '18px', textAlign: 'left', gap: '40px' }}>
          {`${userProfile.first_name} ${userProfile.last_name}`}
        </Typography>
        {userProfile.role_id === 3 ? (
          <Typography sx={{ color: '#838282', fontSize: '12px !important', textAlign: 'left' }}>
            Super Admin
          </Typography>
        ) : (
          <Typography sx={{ color: '#838282', fontSize: '12px !important' }}>
            {`${(userProfile.department_name ?? userProfile.department_id).toString().replaceAll('_', '/')} Dept | ${SECURITY_CLEARANCE[userProfile.security_clearance]} | ${ROLES[userProfile.role_id]}`}
          </Typography>
        )}
      </Box>
    </>
  );

  const getMenuIcon = () => (color === '#FFF' ? <TIIMenuBurger viewBox="0 0 50 50" /> : <TIIMenuBurgerBlack viewBox="0 0 50 50" />);

  return (
    <>
      <IconButton
        aria-label="menu"
        disableRipple
        onClick={handleClick}
        sx={{
          zIndex: 1350,
          marginRight: '10px',
          '& .MuiSvgIcon-root': {
            width: 30,
            height: 50,
          },

          '&:hover': {
            background: 'transparent',
          }
        }}
      >
        {userProfile && (
          <div style={{ marginRight: '0.5rem', color: '#FFF', display: 'flex', flexDirection: 'row' }}>
            {getUserInfo()}
          </div>
        )}
        {open ? <MenuClose viewBox="0 0 50 50" /> : getMenuIcon()}
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="burger-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            boxShadow: '0px 4px 4px 0px rgba(0,0,0,0.25)',
            mt: 2,
            borderRadius: '10px',
            border: '1px solid #f3f3f3',
            backgroundColor: '#EDEDED',
            width: 270,
            display: 'flex',
            p: 1,
            flexDirection: 'column',
            gap: 1.25,
            '& .MuiList-root': {
              p: 0,
              '& .MuiListItem-root': {
                p: 0,
                pt: 0.625,
                pb: 0.625,
                '&:hover': {
                  backgroundColor: '#DCEEFF',
                  color: '#222222',
                  paddingLeft: '15px',
                  '& .MuiListItemIcon-root': {
                    '& .MuiSvgIcon-root svg path': {
                      fill: '#43B6E1',
                    },
                  },
                  '& .MuiTypography-root': {
                    color: '#222222',
                  },
                },
                '& .MuiListItemIcon-root': {
                  mr: 1.25,
                  '& .MuiSvgIcon-root': {
                    width: '20px',
                    height: '20px',
                  },
                },
                '& .MuiTypography-root': {
                  fontSize: '1.125rem',
                },
              },
              '& .MuiDivider-root': {
                mt: 0.625,
                mb: 0.625,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {menuItems.map((item, idx) => {
          const { icon = null, text, redirectTo, visible = true } = item;
          const visibleCount = menuItems.filter(
            (filteredItem) => filteredItem.visible !== false
          ).length;
          return [
            visibleCount > 1 && idx === menuItems.length - 1 && <Divider />,
            visible && (
              <MenuItem component={Link} to={redirectTo} sx={{ '&:hover': { backgroundColor: '#DCEEFF' } }}>
                {icon && <ListItemIcon color="#43B6E1">{icon}</ListItemIcon>}
                <Typography component="span" color="#222222" sx={{ fontSize: '18px', fontStyle: 'normal', fontWeight: 400 }}>
                  {text}
                </Typography>
              </MenuItem>
            ),
          ];
        })}
        {/* {userProfile && [
          <Divider key="div" />,
          <MenuItem disabled key="profile-name" sx={{ opacity: '1 !important', color: '#53565a', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
            {getUserInfo()}
          </MenuItem>
        ]} */}
      </Menu>
    </>
  );
};

export default MainNav;
